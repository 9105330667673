export const locales = {
	en: {
		path: 'en',
		locale: 'English',
		default: true,
		flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAhFBMVEUAAADvjYbwfHSKYpnt7/GKYpp4hcrr7/HwkYnvq6WAd7Xvx8aHaqShaJDLsMOic5vykYzyj4r0Qzbs7/E/UbXvmJPybmTuxMJ7h8zBp77///+HktFvfMfb3/FLW7nz9Pu3vuNjccNsTZSrs99qeMSTndXn6fbP1O3O0+xrXKbNrr9rbbgEiDpcAAAAEnRSTlMAcMDgfuDAgbCgzI7Y2J6UPDtbvABhAAAAz0lEQVRIx+2TSxKCMBBEBQEB/zBgTEIUERS9//1UilUkMRW0KKy8Re/epqdnYjD8L+upiKUtwWpkF0ScIgn2V2QCwAelSnKYZ4cjcFFWVQn3nYR5I3uQYQRcMIwZnGMJTisjRIELRoiiLEBFdqGzMVYqtn3BV8YFfdamJhe44KKpTUFevI5K3oKgvE4l+P0L8wQLU5NFC1ORQ+HC6kRC0LYtWFivl/y5vNqLuH1+yVmshTOwbEda2APLfqqFP+ZTBYkWwZjvvLG02E4MBkMXD5MAkJrGRHOLAAAAAElFTkSuQmCC',
		langBtn: 'English'
	},
	jp: {
		path: 'jp',
		locale: 'Japanese',
		default: false,
		flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8BAMAAADI0sRBAAAAJ1BMVEUAAADs7/Dr7/Hs7/Ls7/HVAADns7TWDg/r4OHnwsPjlZbeWVrbOzyRGGwAAAAABHRSTlMAv4BgC6AmpAAAAJBJREFUOMtjGAW0AIaCOIEwUFrFBSdwGkLSbRl4pN22h4ZWp+CS9jwaCgQxU3BILw8Fgyrs0l6hULAEq/R0mHQlVumjMOkYbNJuoXCQgkXaGyG9BYu0O0K6BIt0K0I6Aot0KkI6jIA0YcMJO42wxwgHC2agkhMliAglIzkgEhPJSRGRkAdtHiNNmlDRMwpoAADdAyGYeDi9yQAAAABJRU5ErkJggg==',
		langBtn: '日本語'
	},
}

export const defaultKey = 'en'
