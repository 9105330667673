import React, { Component } from 'react'
import { ArrowLeft} from 'react-feather'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js'

gsap.registerPlugin(ScrollToPlugin)

class ScrollToTop extends Component {
	constructor(props) {
		super(props);
    	this.state = { 
    		enter: false,
    		leave: false,
    		name: 'backtoTop circleButton'
    	}
      this._isMounted = false;
  	}
	ScrollBtn = () =>{
  	gsap.to(window, {duration:1, scrollTo:0})
	}
  handleScroll = (e) =>{
    let button = document.querySelector('.backtoTop.circleButton');
    if(this._isMounted){
      if(window.scrollY >= 400 ){
        if(this.state.enter === false && this.state.leave === false){
          this.setState({enter: true});
          if(!button.classList.contains('active')) button.classList.add('active')
        }else if(this.state.enter === false && this.state.leave === true){
          this.setState({enter: true});
          this.setState({enter: false});
          if(button.classList.contains !== 'active') button.classList.add('active')
        }else{
          
        }
      }else{
        if(this.state.enter === true && this.state.leave === false){
          this.setState({enter: false});
          this.setState({leave: true});
          if(button.classList.contains('active')) button.classList.remove('active')
        }else{
          if(button.classList.contains('active')) button.classList.remove('active')
        }
      }
    }
  }
	componentDidMount(){	
    this._isMounted = true;
		window.addEventListener('scroll', this.handleScroll);
	}
	componentWillUnmount(){
    this._isMounted = false;
		window.removeEventListener('scroll', this.handleScroll);
	}
	render() {
  	return (
    	<button className={this.state.name} onClick={this.ScrollBtn}>
    		<span className="txt">Back to Top</span>
            <span className="circle"></span>
            <ArrowLeft size='15'/>
    	</button>
  	)
	}
}

export default ScrollToTop
