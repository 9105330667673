import React, { Fragment, Component } from 'react'
import {navigate} from "gatsby"
import _ from 'lodash'
import Nav from './Nav'
import Footer from './Footer'

import ScrollToTop from './ScrollToTop'
import {locales, defaultKey} from './misc/constants/locales'


class LayoutExtraLayer extends Component {
  constructor(props) {
    super(props);
      this.state = { 
        active: false,
        browserLang: 'en',
        currentLang: 'en',
      }
  }
  componentDidMount(){
    let browserLang = this.getBrowserLanguage();
    this.setState({'currentLang': this.getInitLangKey(this.props.path)})
  }
  handleMenuChange = (state) =>{this.setState({active: state})}

  handleLangChange = (state) =>{  
    let path = window.location.pathname
    this.setState({currentLang: state})
  }

  getBrowserLanguage = () =>{
    if(typeof window === 'undefined') return null;

    const first = window.navigator.languages ? window.navigator.languages[0] : null;
    const lang = first || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

    return lang;
  }

  getInitLangKey = (url) =>{ 
    let lang = url.split('/')[1];
    if( lang === '') return defaultKey
    return lang;
  }
  componentDidUpdate(prevprop, prevstate){
    let pathname = (window.location.pathname).split('/')
    if(prevstate.currentLang !== this.state.currentLang){
      if(pathname.length === 2 && this.state.currentLang !== 'en' ) {
        navigate(`/${this.state.currentLang}/`)
      }else if(pathname.length === 3 && this.state.currentLang === 'en' && pathname[2] === ''){
        navigate('/') 
      }else if(pathname.length >= 3 && pathname[2] !== ''){
        navigate(_.replace(window.location.pathname, `/${prevstate.currentLang}/`, `/${this.state.currentLang}/`))
      }
    }
  }

  render() {
    const {children, path} = this.props
    const {active} = this.state

    return (
      <Fragment>
        <Nav active={active} onMenuChange={this.handleMenuChange} onLangChange={this.handleLangChange} lang={this.state.currentLang}/>
        <ScrollToTop />
          <Fragment>{children}</Fragment>
        <Footer active={active} onMenuChange={this.handleMenuChange}/>
      </Fragment>
    )
  }
}

export default LayoutExtraLayer
