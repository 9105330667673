import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import LayoutExtraLayer from './LayoutExtraLayer'

import 'modern-normalize/modern-normalize.css'
import './globalStyles.css'

export default ({ children, title, path}) => {
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
          }
        }
      `}
      render={data => {
        const { siteTitle } = data.settingsYaml || {}
        return (
          <Fragment>
            <Helmet
              defaultTitle={siteTitle}
              titleTemplate={`%s | ${siteTitle}`}
            >
              {title}
              <script src="https://gumroad.com/js/gumroad.js" preload async></script>
            </Helmet>
            <div className={!path ? 'layout notfound' : 'layout'}>
              {path ? (<LayoutExtraLayer children={children} path={path}/>) : <Fragment>{children}</Fragment>}
            </div>            
          </Fragment>
        )
      }}
    />
  )
}
