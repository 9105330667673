import React, { Component } from 'react'
import { Link } from 'gatsby'
import Logo from './Logo'
import {Facebook, Twitter, Linkedin, BarChart2, X} from 'react-feather';
import {locales} from './misc/constants/locales'
import _ from 'lodash'

import './Nav.css'

export class Nav extends Component {
  constructor(props){
    super(props)

    this.state ={
      isLangOpen: false,
    }
  }

  handleMenuToggle = () => this.props.onMenuChange(!this.props.active);

  handleLangOpen = (e) => {
    this.setState({isLangOpen: !this.state.isLangOpen});
  }

  handleLangChange = (state, e) => {
    e.stopPropagation();
    this.props.onLangChange(state)
  };

  // Only close nav if it is open
  handleLinkClick = () => this.props.active && this.handleMenuToggle()

  render() {
    const { active, lang } = this.props,
        {isLangOpen} = this.state,
      NavLink = ({ to, className, children, ...props }) => (
        <Link to={to} className={`NavLink ${className}`} onClick={this.handleLinkClick} {...props}>
          {children}
        </Link>
      )

    return (
      <nav className={`header ${active ? 'Nav-active' : ''}`}>
        <div className="header__inner">
          <h1>
            <Link to={lang === 'en' ? '/' : `/${lang}/`} onClick={this.handleLinkClick}>
              <Logo />
            </Link>
          </h1>
  
          <div className="Nav--Links">
            <div className="contactDetails">
              <h2>Wanna Say hello?</h2>
              <p className="dropTxt"> Just drop me a line at</p>
              <a href="mailto:perryleijten@gmail.com" className="email">perryleijten@gmail.com</a>
              <ul className="menu_social">
                <li className="menu__item">
                  <a href="https://www.facebook.com/perry.leijten" target="_blank" rel="noopener noreferrer">
                    <Facebook size={30} className="FB" />
                  </a>
                </li>
                <li className="menu__item">
                  <a href="https://twitter.com/perryleijten" target="_blank" rel="noopener noreferrer">
                    <Twitter size={30}  className="twitter"/>
                  </a>
                </li>
                <li className="menu__item">
                  <a href="http://nl.linkedin.com/pub/perry-leijten/24/4ab/415" target="_blank" rel="noopener noreferrer">
                    <Linkedin size={30} className="linkedin"/>
                  </a>
                </li>        
              </ul>
            </div>
            <div className="menuDetailContainer">
              <NavLink to={ lang === 'en' ? '/' : `/${lang}/`}>Home</NavLink>
              <NavLink to={`/${lang}/works/`}>All Work</NavLink>
              <NavLink to={`/${lang}/works/personalWork`}>Personal Work</NavLink>
              <NavLink to={`/${lang}/works/professionalWork`}>Professional Work</NavLink>
              <NavLink to={`/${lang}/works/toolsTechartTopics`}>Tools & Techart</NavLink>
            </div>
          </div>
          <div className="Nav--Button_Container">
            <button className="app-menu-language" onClick={this.handleLangOpen}>
              <div className="app-popup is-open">
                <div className="app-popup-toggle">
                  <div className="lang-icon">
                    <img src={locales[lang].flag} alt="language flag" />
                  </div>
                </div>
                {isLangOpen && (
                <div className="app-popup-content is-bottom-right">
                  <div className="list is-dark">
                    {_.map(locales, (l, i) => (
                    <div className="list-item" key={`flagItem${i}`} onClick={(e)=>this.handleLangChange(i, e)}>
                        <div>{l.langBtn}</div>
                    </div>
                  ))}
                  </div>
                </div>
                )}
              </div>
            </button>            
            <button className="Button-blank Nav--MenuButton" onClick={this.handleMenuToggle}>
              {active ? <X /> : <BarChart2 />}
            </button>
          </div>
        </div>
      </nav>
    )
  }
}

export default Nav
